/* body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
*/

 



:root{
  --font-family-quicksand: 'Quicksand', sans-serif;
  --font-family-lato: 'Lato', sans-serif;

  --primary: #5D84C1;
  --teal: #253D4E;
  --success: #DEF9EC;
  --disabled: #BCCDE9;
  --danger: #FD6E6E;
  --info: #1877F2;
  --white: #fff;

  --secondary: 
}

.btn {
  font-weight: bold;
  color:var(--white);
  cursor: pointer;
  transition: transform 0.3s ease;
  white-space: nowrap;
  text-align: center;
  display: inline-block;
  font-family: var(--font-family-quicksand);
}
.btn:hover{
  color:var(--white);
}
.btn:visited{
  border:none;
  outline: none;
}

/* BUTTON STYLES */
.btn-main {
  background: var(--primary);
  color: var(--white);
  border: none;
  border-radius: 4px;
}

.btn-main-outline {
  background: var(--primary);
  color: var(--white);
}
/* BUTTON SIZES */
.btn-round {
  background: var(--disabled);
  border: 2px solid var(--teal);
  border-radius: 32px;
}
.btn-xl {
  width: 368px;
  font-size: 24px;
  padding: 15px 20px;
}
.btn-l {
  width: 256px;
  font-size: 24px;
  padding: 15px 20px;
}
.btn-md {
  width: 182px;
  font-size: 24px;
  padding: 15px 20px;
}

.btn-sm {
  width: 140px;
  font-size: 18px;
  padding: 10px 20px;
}
.btn- .btn-xs {
  width: 100px;
  font-size: 12px;
  padding: 10px 20px;
}

/* BUTTON SHADOWS*/
.shadowbtn-none {
  box-shadow: none;
  filter: none;
}
.shadowbtn-light {
  box-shadow: none;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.nowrap {
  display: flex;
  align-items: center;
  white-space: nowrap;
  justify-content: center;
}