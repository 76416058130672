.header-main {
  height: 60px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.02);
  width: 100%;
  padding: 0 3%;
  background-color: white;
  z-index: 999;
}

.header-list {
  list-style: none;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  column-gap: 20px;
  margin-right: 24px;
}

.header-chat img {
  margin-right: 10px;
}

.header-profile {
  padding-left: 20px;
  border-left: 1px solid #f4f4f4;
  column-gap: 8px;
  margin-right: 25px;
  height: 100%;
  cursor: pointer;
}

.header-profile img {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border: 1px solid #000000;
  object-fit: cover;
  object-position: center;
  cursor: pointer;
}

.header-text {
  font-family: Lato;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.01em;
  color: #3e3e3e;
}

.header-right-content {
  display: flex;
  align-items: center;
  cursor: pointer;
}

@media (max-width: 767px) {
  .header-right-content {
    display: none;
  }
}

/* .headerMenu {
  padding: 8px;
} */

.drop-menu {
  position: absolute;
  top: 25px !important;
  left: -65px !important;
  filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.25));
  border: none!important;
}

.headerMenu button {
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  color: rgba(24, 24, 25, 0.9);
  border-radius: 4px;
}

.headerMenu button:hover {
  background: rgba(86, 87, 88, 0.04);
}

.search-api:hover {
  background: #5d84c1;
  color: #ffff;
  border-radius: 6px;
}
