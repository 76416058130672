.cway-footer {
  border-top: 1px solid #d8f1e5;
}

.copyright {
  border-top: 1px solid #d8f1e5;
}

.cway-footer span {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #253d4e;
}

.cway-footer article {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.5rem;
  color: #253d4e;
}

.logo {
  cursor: pointer;
}

.cway-footer h6 {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 2rem;
  letter-spacing: -0.0004em;
  color: #253d4e;
}

.cway-footer ul {
  list-style: none;
  padding: 0;
}

.cway-footer li {
  margin-bottom: 1rem;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.5rem;
  color: #253d4e;
}

.cway-footer p {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.313rem;
  color: #7e7e7e;
}
